import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import MainTemplate from '../../templates/MainTemplate';
import Map from '../../components/Map';
import FilterAttrazioni from '../../api/GetAttrazioni';
import GetCategorie from '../../api/GetCategorie';
import { Markdown } from '../../utils/markdown';
import BreadCrumb from '../../components/BreadCrumb';
import { useIsPortrait } from '../../utils/platformUtils';

import './routing.css';

const Attrazione = (props) => {

  const { route, navigation } = props;
  const idAttrazione = route.params.attrazione;
  const titolo = FilterAttrazioni(route.params.attrazione, "attrazione", "Nome");
  const titoloSottotitoloCategoria = GetCategorie(props, "attrazione", route.params.categoria);
  const titoloCategoria = titoloSottotitoloCategoria && titoloSottotitoloCategoria.length > 0 ? titoloSottotitoloCategoria[0] : '';
  const descrizione = FilterAttrazioni(route.params.attrazione, "attrazione", "Descrizione");
  const descrizioneBreve = FilterAttrazioni(route.params.attrazione, "attrazione", "DescrizioneBreve");
  const coordinate = FilterAttrazioni(route.params.attrazione, "attrazione", "Posizione");
  const percorso = FilterAttrazioni(route.params.attrazione, "attrazione", "Percorso");
  const altre = FilterAttrazioni(route.params.categoria, "attrazione2", route.params.attrazione);
  const koomot = FilterAttrazioni(route.params.attrazione, "attrazione", "LinkKoomot");

  document.title = titolo+' - Guida Interattiva - Orobie Alps Resort';
  

  const point = coordinate ? {
    id: idAttrazione,
    nome: titolo,
    descrizione: descrizioneBreve,
    point: coordinate
  } : undefined;

  const polygon = percorso ? {
    id: idAttrazione,
    nome: titolo,
    polygon: percorso
  } : undefined;
  
  const points = [];
  if(altre !== undefined && altre.length > 0){
    for(let i = 0; i < altre.length; i++){
      points.push({
        id: altre[i][0],
        nome: altre[i][1],
        descrizione: altre[i][2],
        point: altre[i][3]
      })
    }
  }

  const isPortrait = useIsPortrait()
  
  
  return (
    <MainTemplate {...props} noBorders={true} breadcrumb={
      <BreadCrumb onlyLast={isPortrait} pages={[
        ['Home', () => {navigation.replace("Guida")}],
        [titoloCategoria, () => {navigation.replace("Categoria", {categoria: route.params.categoria})}],
        [titolo, '']
      ]} />
    }>

      <Text style={styles.title}>{titolo}</Text>

      <Text style={styles.explain}>Nella mappa interattiva sono presenti tutte le attrazioni per {titoloCategoria}.
      In <Text style={{color:'red'}}>rosso</Text> {titolo}, in <Text style={{color:'green'}}>verde</Text> il resort, in <Text style={{color:'blue'}}>blu</Text> le altre.
      Inoltre, puoi seguire l'itinerario tramite Koomot quando disponibile!
      Clicca nella mappa per maggiori informazioni!</Text>
      
      <View style={styles.mapbox}>
        <Map {...props} subjectMarker={point} otherMarkers={points} polygon={polygon} koomot={koomot} />
      </View>

      <Text style={styles.subtitle}>MAGGIORI INFORMAZIONI</Text>

      <View style={styles.downBox}>
        <Markdown>{descrizione}</Markdown>
      </View>

    </MainTemplate>
  );
  
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#f0f0f0'
  },
  explain: {
    fontSize: 12,
    padding: 15
  },
  mapbox: {
    width: '100%',
    height: '60%'
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    borderTopColor: 'black',
    borderTopWidth: 3
  },
  downBox: {
    paddingHorizontal: 30,
    paddingVertical: 20,
    backgroundColor: '#ffffff',
    whiteSpace: 'pre-wrap',
    fontFamily: 'BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
  },
});

export default Attrazione;